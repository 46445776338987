import React, { useState, useEffect, useContext } from 'react';
import { 
  Smartphone, 
  MessageCircle, 
  UserX, 
  CreditCard, 
  Zap, 
  Globe, 
  Shield, 
  ShoppingBag, 
  Calendar, 
  ShoppingCart, 
  Globe2, 
  ArrowRight, 
  Pointer 
} from 'lucide-react';
import { LanguageContext, LanguageProvider } from './contexts/LanguageContext';
import { translations } from './locales/translations';

// アイコン配列の定義
const StepsIcon = [ShoppingCart, CreditCard, Smartphone, Zap];
const FeaturesIcon = [Shield, MessageCircle, UserX];
const ReasonsIcon = [Globe, ShoppingBag, CreditCard, Zap];

const PurchaseFlow = () => {
  const { language } = useContext(LanguageContext);
  const steps = translations[language].purchaseFlow.steps;

  return (
    <div className="bg-gradient-to-r from-blue-600 to-indigo-600 p-4 sm:p-10 rounded-2xl shadow-2xl">
      <h2 className="text-xl sm:text-3xl font-bold text-white mb-6 text-center">
        {translations[language].purchaseFlow.title}
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-4 gap-8 sm:gap-8">
        {steps.map((step, index) => (
          <div key={index} className="flex flex-col items-center text-center relative">
            <div className="flex items-center mb-3">
              <div className="flex items-center justify-center w-10 h-10 sm:w-14 sm:h-14 rounded-full bg-white text-indigo-900 font-bold text-lg sm:text-2xl mr-2 shadow-lg">
                {index + 1}
              </div>
              <div className="flex items-center justify-center w-8 h-8 sm:w-12 sm:h-12 rounded-full bg-indigo-400">
                {React.createElement(StepsIcon[index], { className: "w-4 h-4 sm:w-6 sm:h-6 text-white" })}
              </div>
            </div>
            <h3 className="text-base sm:text-xl font-semibold text-white mb-1">{step.title}</h3>
            <p className="text-sm sm:text-base text-white/90">{step.description}</p>
            {index < steps.length - 1 && (
              <>
                <div className="hidden md:block absolute top-1/2 -right-4 w-4 h-4 border-t-2 border-r-2 border-white/70 transform rotate-45 translate-x-1/2 -translate-y-1/2"></div>
                <div className="md:hidden absolute left-1/2 -bottom-6 w-4 h-4 border-b-2 border-r-2 border-white/70 transform rotate-45 translate-x-1/2"></div>
              </>
            )}
          </div>
        ))}
      </div>
      <div className="text-center mt-10">
        <a href="https://prepaid-sim.shop/esim" className="inline-block bg-gradient-to-r from-green-400 to-blue-500 text-white px-8 py-4 rounded-full text-lg font-semibold hover:from-green-500 hover:to-blue-600 transition duration-300 transform hover:scale-105 shadow-lg">
          {translations[language].buyNow}
        </a>
      </div>
    </div>
  );
};

const PrePaidSimLP = () => {
  const [animate, setAnimate] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isContactOpen, setIsContactOpen] = useState(false);
  const { language, setLanguage } = useContext(LanguageContext);
  const [scrollX, setScrollX] = useState(0);

  useEffect(() => {
    setAnimate(true);

    const handleScroll = () => {
      setIsScrolled(window.scrollY > 100);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest('.contact-dropdown')) {
        setIsContactOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleScroll = (e) => {
      if (e.target.classList.contains('pricing-scroll')) {
        setScrollX(e.target.scrollLeft);
      }
    };

    const pricingTable = document.querySelector('.pricing-scroll');
    if (pricingTable) {
      pricingTable.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (pricingTable) {
        pricingTable.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  const pricingData = translations[language].pricing.plans;

  const handleLanguageChange = (lang) => {
    setLanguage(lang);
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-50 via-blue-50 to-indigo-100 text-gray-800">
      <header className="py-4 sm:py-6 bg-white/90 backdrop-blur-md border-b border-gray-200">
        <div className="container mx-auto px-4 flex justify-between items-center">
          <h1 className={`text-2xl sm:text-4xl font-extrabold tracking-tight ${animate ? 'animate-fade-in-down' : 'opacity-0'}`}>
            {translations[language].title}
          </h1>
          
          {/* 右側のボタングループ */}
          <div className="flex items-center space-x-2 sm:space-x-4">
            {/* 言語切り替えボタン */}
            <div className="relative group">
              <button className="flex items-center space-x-2 bg-indigo-100 hover:bg-indigo-200 px-4 py-2 rounded-lg transition-all text-indigo-700">
                <Globe2 className="w-5 h-5" />
                <span>{language.toUpperCase()}</span>
              </button>
              <div className="absolute right-0 mt-2 w-32 bg-white rounded-lg shadow-lg opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all z-50">
                <button
                  onClick={() => handleLanguageChange('ja')}
                  className="block w-full text-left px-4 py-2 text-gray-700 hover:bg-indigo-50 rounded-t-lg"
                >
                  日本語
                </button>
                <button
                  onClick={() => handleLanguageChange('en')}
                  className="block w-full text-left px-4 py-2 text-gray-700 hover:bg-indigo-50"
                >
                  English
                </button>
                <button
                  onClick={() => handleLanguageChange('zh')}
                  className="block w-full text-left px-4 py-2 text-gray-700 hover:bg-indigo-50"
                >
                  中文
                </button>
                <button
                  onClick={() => handleLanguageChange('vi')}
                  className="block w-full text-left px-4 py-2 text-gray-700 hover:bg-indigo-50 rounded-b-lg"
                >
                  Tiếng Việt
                </button>
              </div>
            </div>

            {/* マイページボタン */}
            <a
              href="https://prepaid-sim.shop/mypage"
              className="flex items-center space-x-2 bg-indigo-500 px-4 py-2 rounded-lg hover:bg-indigo-600 transition-all text-white"
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
              </svg>
              <span className="hidden sm:inline">{translations[language].navigation.mypage}</span>
            </a>
          </div>
        </div>
      </header>

      <main className="container mx-auto px-4 py-6 sm:py-12">
        <section className="mb-8 sm:mb-20 text-center">
          <h2 className={`text-xl sm:text-5xl font-bold mb-2 sm:mb-6 ${animate ? 'animate-fade-in-up' : 'opacity-0'}`}>
            {translations[language].subtitle}
          </h2>
          <p className={`text-xs sm:text-xl mb-3 sm:mb-8 ${animate ? 'animate-fade-in-up delay-200' : 'opacity-0'}`}>
            {translations[language].physicalAndESim}
          </p>
          <a href="https://prepaid-sim.shop/esim" className="inline-block bg-gradient-to-r from-blue-500 to-indigo-600 text-white px-8 sm:px-8 py-4 sm:py-4 rounded-full text-lg sm:text-lg font-semibold hover:from-blue-600 hover:to-indigo-700 transition duration-300 transform hover:scale-105 shadow-lg">
            {translations[language].buyNow}
          </a>
        </section>

        <section className="mb-8 sm:mb-20 bg-gradient-to-r from-yellow-400 to-orange-400 p-4 sm:p-8 rounded-xl text-gray-900 shadow-lg">
          <h2 className="text-xl sm:text-3xl font-bold mb-3 text-center">
            {translations[language].campaign.title}
          </h2>
          <div className="flex flex-col sm:flex-row items-center justify-center space-y-2 sm:space-y-0 sm:space-x-4">
            <Calendar className="w-8 h-8 sm:w-12 sm:h-12" />
            <p className="text-sm sm:text-xl font-semibold text-center sm:text-left">
              {translations[language].campaign.description}
            </p>
          </div>
          <p className="mt-3 text-center text-xs sm:text-base">
            {translations[language].campaign.notice}
          </p>
          <p className="mt-3 text-center text-xs sm:text-base break-words">
            {translations[language].campaign.banner}
          </p>
        </section>

        <section className="mb-12 sm:mb-20">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 sm:gap-8">
            {translations[language].features.items.map((item, index) => {
              const IconComponent = FeaturesIcon[index];
              return (
                <div key={index} className="bg-white/90 backdrop-blur-md p-6 sm:p-8 rounded-xl shadow-lg hover:shadow-xl transition-all duration-300 transform hover:scale-105">
                  <IconComponent className="w-10 h-10 sm:w-12 sm:h-12 text-indigo-500 mb-4" aria-hidden="true" />
                  <h3 className="text-xl sm:text-2xl font-semibold mb-2 sm:mb-4 text-gray-800">{item.title}</h3>
                  <p className="text-sm sm:text-base text-gray-600">{item.description}</p>
                </div>
              );
            })}
          </div>
        </section>

        <section className="mb-8 sm:mb-20">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-8">
            {translations[language].reasons.items.map((item, index) => {
              const IconComponent = ReasonsIcon[index];
              return (
                <div 
                  key={index} 
                  className="flex items-start space-x-3 sm:space-x-4 bg-gradient-to-r from-indigo-600 to-blue-600 p-4 sm:p-6 rounded-lg shadow-lg hover:shadow-xl transition-all duration-300 transform hover:scale-105 hover:-translate-y-1"
                >
                  <IconComponent className="w-5 h-5 sm:w-8 sm:h-8 text-white flex-shrink-0" aria-hidden="true" />
                  <div>
                    <h3 className="text-base sm:text-xl font-semibold text-white mb-1 sm:mb-2">{item.title}</h3>
                    <p className="text-xs sm:text-base text-white/90">{item.description}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </section>

        <section className="mb-12 sm:mb-20 relative" aria-labelledby="pricing-title">
          {/* 料金プラン全体のコンテナ */}
          <div className="bg-white/90 backdrop-blur-md rounded-2xl shadow-lg overflow-hidden border border-indigo-100">
            {/* タイトル部分 */}
            <div className="bg-gradient-to-r from-indigo-600 to-blue-600 p-8 sm:p-10">
              <h2 id="pricing-title" className="text-2xl sm:text-4xl font-bold text-center text-white">
                {translations[language].pricing.title}
              </h2>
              <p className="mt-2 text-center text-white/90 text-sm sm:text-base">
                {translations[language].pricing.subtitle || "Choose the best plan for you"}
              </p>
            </div>

            {/* 料金表 */}
            <div className="overflow-x-auto pricing-scroll p-6 sm:p-8 relative">
              {/* スワイプヒント */}
              <div 
                className="md:hidden fixed z-50 flex items-center pointer-events-none"
                style={{
                  left: `${Math.min(
                    Math.max(window.innerWidth / 2 + scrollX, window.innerWidth / 2),
                    document.querySelector('.pricing-scroll')?.scrollWidth - window.innerWidth / 2
                  )}px`,
                  top: '50%',
                  transform: 'translate(-50%, -50%)'
                }}
              >
                <div className="flex items-center px-4 py-2 rounded-full bg-transparent">
                  <Pointer 
                    className="w-5 h-5 text-indigo-600 animate-pulse mr-2 drop-shadow-lg rotate-90" 
                    aria-hidden="true"
                  />
                  <ArrowRight 
                    className="w-5 h-5 text-indigo-600 animate-swipe drop-shadow-lg" 
                    aria-hidden="true"
                  />
                </div>
              </div>

              <table className="w-full min-w-[600px]">
                <thead>
                  <tr className="border-b-2 border-indigo-100">
                    <th className="p-4 text-left font-bold text-xs sm:text-lg text-indigo-900">
                      {translations[language].pricing.headers.data}
                    </th>
                    <th className="p-4 text-left font-bold text-xs sm:text-lg text-indigo-900">
                      {translations[language].pricing.headers.initial}
                    </th>
                    <th className="p-4 text-left font-bold text-xs sm:text-lg text-indigo-900">
                      {translations[language].pricing.headers.extension}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {pricingData.map((plan, index) => (
                    <tr 
                      key={index} 
                      className={`
                        border-b border-indigo-50 hover:bg-indigo-50/30 transition-all duration-300
                        ${index % 2 === 0 ? 'bg-transparent' : 'bg-indigo-25/20'}
                      `}
                    >
                      <td className="p-4 font-semibold text-xs sm:text-base text-indigo-700" 
                          dangerouslySetInnerHTML={{ __html: plan.data }}>
                      </td>
                      <td className="p-4 text-xs sm:text-base">
                        <span className="inline-flex items-center font-semibold text-indigo-900">
                          <span className="text-sm sm:text-xl">{plan.initialPrice}</span>
                        </span>
                      </td>
                      <td className="p-4 text-xs sm:text-base">
                        <span className="inline-flex items-center font-semibold text-indigo-900">
                          <span className="text-sm sm:text-xl">{plan.extensionPrice}</span>
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </section>

        <section className="mb-12 sm:mb-20" aria-labelledby="purchase-flow-title">
          <h2 id="purchase-flow-title" className="sr-only">{translations[language].purchaseFlow.title}</h2>
          <div className={`${animate ? 'animate-fade-in-up delay-200' : 'opacity-0'}`}>
            <PurchaseFlow />
          </div>
        </section>

        <section className="mb-12 sm:mb-20 bg-gradient-to-br from-indigo-600 to-blue-700 p-6 sm:p-8 rounded-xl backdrop-filter backdrop-blur-lg">
          <h2 className="text-2xl sm:text-3xl font-bold mb-4 sm:mb-6 text-center text-white">
            {translations[language].usage.title}
          </h2>
          {translations[language].usage.description.map((text, index) => (
            <p key={index} className="text-sm sm:text-lg mb-3 sm:mb-4 text-white/90">
              {text}
            </p>
          ))}
          <p className="text-sm sm:text-lg font-semibold text-yellow-300 mt-4">
            {translations[language].usage.campaign}
          </p>
        </section>

        <section className="mb-12 sm:mb-20" id="faq">
          <div className="space-y-4">
            {translations[language].faq.items.map((item, index) => (
              <div 
                key={index} 
                id={`faq-${index + 1}`} 
                className="bg-white/90 backdrop-blur-md rounded-xl overflow-hidden shadow-lg hover:shadow-xl transition-all duration-300"
              >
                <details className="group">
                  <summary className="flex items-center gap-4 p-6 cursor-pointer select-none">
                    {/* Q マーク */}
                    <span className="flex-shrink-0 flex items-center justify-center w-8 h-8 rounded-full bg-indigo-100 text-indigo-700 font-bold">
                      Q
                    </span>
                    {/* 質問文 */}
                    <h3 className="text-base sm:text-lg font-semibold text-gray-800 group-hover:text-indigo-600 transition-colors">
                      <a href={`#faq-${index + 1}`} className="hover:underline">
                        {item.question}
                      </a>
                    </h3>
                    {/* 矢印アイコン */}
                    <span className="flex-shrink-0 ml-auto transform group-open:rotate-180 transition-transform duration-300">
                      <svg className="w-5 h-5 text-indigo-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                      </svg>
                    </span>
                  </summary>
                  
                  {/* アンサー部分 */}
                  <div className="px-6 pb-6">
                    <div className="flex gap-4 p-6 bg-gradient-to-br from-indigo-50 to-blue-50 rounded-xl">
                      {/* A マーク */}
                      <span className="flex-shrink-0 flex items-center justify-center w-8 h-8 rounded-full bg-indigo-500 text-white font-bold">
                        A
                      </span>
                      {/* 回答文 */}
                      <div className="flex-grow">
                        <p className="text-sm sm:text-base leading-relaxed text-gray-700">
                          {item.answer}
                        </p>
                      </div>
                    </div>
                  </div>
                </details>
              </div>
            ))}
          </div>
        </section>

        <section id="apply" className="text-center mb-8">
          <h2 className={`text-2xl sm:text-3xl font-bold mb-4 sm:mb-6 ${animate ? 'animate-fade-in-up' : 'opacity-0'}`}>
            {translations[language].startNow}
          </h2>
          <a href="https://prepaid-sim.shop/esim" className="inline-block bg-gradient-to-r from-green-400 to-blue-500 text-white px-8 sm:px-8 py-4 sm:py-4 rounded-full text-lg sm:text-lg font-semibold hover:from-green-500 hover:to-blue-600 transition duration-300 transform hover:scale-105 shadow-lg">
            {translations[language].buyNow}
          </a>
          <p className={`mt-2 text-xs sm:text-base text-black-300 font-semibold ${animate ? 'animate-fade-in-up delay-400' : 'opacity-0'}`}>
            {translations[language].campaign.banner}
          </p>
        </section>
      </main>

      <nav className={`fixed bottom-0 left-0 right-0 z-50 transition-all duration-300 ${
        isScrolled ? 'bg-white shadow-lg' : 'bg-white/90 backdrop-blur-md'
      }`}>
        <div className="container mx-auto px-4 py-2 flex justify-between items-center">
          <div className="text-xl font-bold"></div>
          <div className="space-x-2 sm:space-x-4">
            <a 
              href="https://prepaid-sim.shop/mypage" 
              className="inline-block bg-indigo-500 text-white px-4 py-2 rounded-lg text-sm font-semibold hover:bg-indigo-600 transition-all"
            >
              {translations[language].navigation.mypage}
            </a>
            
            {/* お問い合わせドロップダウン */}
            <div className="relative inline-block contact-dropdown">
              <button 
                onClick={(e) => {
                  e.stopPropagation();
                  setIsContactOpen(!isContactOpen);
                }}
                className="inline-block bg-indigo-100 text-indigo-700 px-4 py-2 rounded-lg text-sm font-semibold hover:bg-indigo-200 transition-all"
              >
                {translations[language].navigation.contact}
              </button>
              <div 
                className={`absolute bottom-full mb-2 right-0 w-48 bg-white rounded-lg shadow-lg overflow-hidden transition-all duration-300 ${
                  isContactOpen 
                    ? 'opacity-100 visible transform translate-y-0' 
                    : 'opacity-0 invisible transform translate-y-2'
                }`}
              >
                <a 
                  href="https://lin.ee/jycCY2t" 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className="flex items-center px-4 py-3 text-gray-700 hover:bg-gray-100 transition duration-300"
                  onClick={() => setIsContactOpen(false)}
                >
                  <svg className="w-5 h-5 mr-3" viewBox="0 0 24 24">
                    <path fill="#00B900" d="M19.365 9.863c.349 0 .63.285.63.631 0 .345-.281.63-.63.63H17.61v1.125h1.755c.349 0 .63.283.63.63 0 .344-.281.629-.63.629h-2.386c-.345 0-.627-.285-.627-.629V8.108c0-.345.282-.63.63-.63h2.386c.346 0 .627.285.627.63 0 .349-.281.63-.63.63H17.61v1.125h1.755zm-3.855 3.016c0 .27-.174.51-.432.596-.064.021-.133.031-.199.031-.211 0-.391-.09-.51-.25l-2.443-3.317v2.94c0 .344-.279.629-.631.629-.346 0-.626-.285-.626-.629V8.108c0-.27.173-.51.43-.595.06-.023.136-.033.194-.033.195 0 .375.104.495.254l2.462 3.33V8.108c0-.345.282-.63.63-.63.345 0 .63.285.63.63v4.771zm-5.741 0c0 .344-.282.629-.631.629-.345 0-.627-.285-.627-.629V8.108c0-.345.282-.63.63-.63.346 0 .628.285.628.63v4.771zm-2.466.629H4.917c-.345 0-.63-.285-.63-.629V8.108c0-.345.285-.63.63-.63.348 0 .63.285.63.63v4.141h1.756c.348 0 .629.283.629.63 0 .344-.282.629-.629.629M24 10.314C24 4.943 18.615.572 12 .572S0 4.943 0 10.314c0 4.811 4.27 8.842 10.035 9.608.391.082.923.258 1.058.59.12.301.079.766.038 1.08l-.164 1.02c-.045.301-.24 1.186 1.049.645 1.291-.539 6.916-4.078 9.436-6.975C23.176 14.393 24 12.458 24 10.314"/>
                  </svg>
                  {translations[language].navigation.contactOptions.line}
                </a>
                <a 
                  href="https://t.me/prepaid_jp" 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className="flex items-center px-4 py-3 text-gray-700 hover:bg-gray-100 transition duration-300"
                  onClick={() => setIsContactOpen(false)}
                >
                  <svg className="w-5 h-5 mr-3" viewBox="0 0 24 24">
                    <path fill="#229ED9" d="M19.2,4.4L2.9,10.7c-1.1,0.4-1.1,1.1-0.2,1.3l4.1,1.3l1.6,4.8c0.2,0.5,0.1,0.7,0.6,0.7c0.4,0,0.6-0.2,0.8-0.4 c0.1-0.1,1-1,2-2l4.2,3.1c0.8,0.4,1.3,0.2,1.5-0.7l2.8-13.1C20.6,4.6,19.9,4,19.2,4.4z M17.1,7.4l-7.8,7.1L9,17.8L7.4,13l9.2-5.8 C17,6.9,17.4,7.1,17.1,7.4z"/>
                    <path fill="#229ED9" d="M12,0C5.4,0,0,5.4,0,12s5.4,12,12,12s12-5.4,12-12S18.6,0,12,0z M12,22C6.5,22,2,17.5,2,12S6.5,2,12,2 s10,4.5,10,10S17.5,22,12,22z"/>
                  </svg>
                  {translations[language].navigation.contactOptions.telegram}
                </a>
                <a 
                  href="https://prepaid-sim.shop/contact" 
                  className="flex items-center px-4 py-3 text-gray-700 hover:bg-gray-100 transition duration-300"
                  onClick={() => setIsContactOpen(false)}
                >
                  <svg className="w-5 h-5 mr-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                  </svg>
                  {translations[language].navigation.contactOptions.form}
                </a>
              </div>
            </div>

            <a href="https://prepaid-sim.shop/esim" className="inline-block bg-green-500 text-white px-3 py-2 rounded text-sm font-semibold hover:bg-green-600 transition duration-300">
              {translations[language].navigation.purchase}
            </a>
          </div>
        </div>
      </nav>
      <footer className="py-2 text-center pb-16">
        <p className="text-xs">{translations[language].footer.copyright}</p>
      </footer>
    </div>
  );
};

// アプリ全体をLanguageProviderでラップ
const AppWrapper = () => (
  <LanguageProvider>
    <PrePaidSimLP />
  </LanguageProvider>
);

export default AppWrapper;